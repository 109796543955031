import { GatsbyPageProps, Token } from '@/types';
import { ArticleList } from '../../articles';
import Xxx20WeekMaChartTemplate from '@/templates/crypto/xxx-20-week-moving-average-chart';

export default function Ethereum20WeekMaChart({ location }: GatsbyPageProps) {
  return (
    <Xxx20WeekMaChartTemplate
      coinAbbreviation="ETH"
      coinFullName="Ethereum"
      token={Token.ETHUSDT}
      location={location}
      articleId={ArticleList.ETHEREUM_20_WEEK_MOVING_AVERAGE_CHART}
    />
  );
}
